@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

* {
    font-family: 'Fira Sans', sans-serif;
}

.body {
    width: 50vw;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#root {
    background: linear-gradient(-45deg, #ee76528c, #e73c7e81, #23a5d577, #23d5ab7e);
}