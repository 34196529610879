.result {
    margin-top: 50px;
}

.result-header p {
    margin: 0px;
    font-size: 1.6rem;
    background: linear-gradient(-45deg, #ee76528c, #e73c7e81, #23a5d577, #23d5ab7e);
    background-size: 400% 400%;
    animation: gradient 2.5s ease infinite;
    padding: 5px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.794);
}

.result-header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.result-header img {
    width: 150px;
    margin-left: 10px;
    height: 50px;
    object-fit: contain;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.copyGuide {
    background-color: #54bcf9;
    cursor: pointer;
    color: white;
    border: none;
    padding: 10px;
    font-size: 1.2rem;
    width: 100%;
    border-radius: 5px;
    transition: 0.2s linear all;
}

.copyGuide:hover {
    background-color: #3193cc;
}

.guideContainer {
    border-radius: 5px;
    margin-bottom: 20px;
}

.guideContainer p {
    font-size: 2rem;
    padding: 5px;
    margin: 0;
    font-weight: 600;
}

.guideList {
    margin: 0;
    font-size: 18px;
}
