.body {
    width: 50vw;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.header {
    margin: 0;
    margin-top: 30px;
    font-size: 40px;
    color: #ffffff;
    display: flex;
    margin-bottom: 20px;
    text-shadow: 0px 4px #474747;
}

.header img {
    width: 50px;
    margin-right: 10px;
}

.secondHeader {
    margin: 0;
    margin-bottom: 30px;
    font-size: 25px;
}

.search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    font-size: 22px;
    margin-top: 10px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 10px 10px 23px -10px rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 10px 10px 23px -10px rgba(0, 0, 0, 0.36);
    -moz-box-shadow: 10px 10px 23px -10px rgba(0, 0, 0, 0.36);
}

.number {
    width: 300px;
    height: 40px;
    font-size: 25px;
    border: 0px solid black;
    background-color: #54bcf912;
    outline: none;
}

.searchButton {
    width: 300px;
    height: 40px;
    border: 0px;
    cursor: pointer;
    background-color: #54bcf9;
    color: white;
    font-size: 24px;
    font-weight: 600;
    transition: 0.2s linear all;
}

.searchButton:hover {
    background-color: #3193cc;
}

.error {
    color: red;
    font-size: 20px;
    text-align: center;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #54bcf948;
    border-top: 10px solid #54bcf9;
    border-radius: 50%;
    animation: spinner 0.5s linear infinite;
    margin-top: 20px;
}

.not-working {
    background-color: red;
    color: white;
    width: 500px;
    padding: 20px;
    border-radius: 40px;
}